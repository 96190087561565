import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {FireBaseAuthService} from './services/fire-base-auth.service';
import {UserService} from './services/user.service';
import {MessageService, SelectItem} from 'primeng/api';
import {User} from './interface/home';
import {Idle} from '@ng-idle/core';
//import { DeliverableFulfillmentService } from './services/deliverable-fulfillment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { AppReviewService } from './services/app-review.service';
//import { AppFeedbackService } from './services/app-feedback.service';

@Component({
    selector: 'app-topbar',
    template: `
		<p-dialog header="Review the application" [(visible)]="displayReview" modal="modal" showEffect="fade" [style]="{width: '500px'}">
			<div style="line-height: 1.5">			
				<form [formGroup]="reviewForm" (ngSubmit)="submitReview()">
					<div class="p-fluid">		
						<div class="p-field p-grid">
							<label for="head" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Module</label>
							<div class="p-col-12 p-md-9">
								<p-dropdown inputId="head" [options]="applicationModules" [(ngModel)]="selectedModule" placeholder="Select Module" [showClear]="true" [filter]="true" formControlName="module">
								</p-dropdown>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="uirating" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Look and Feel Rating</label>
							<div class="p-col-12 p-md-9">
								<p-rating id="uirating" formControlName="uiux"></p-rating>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="frating" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Functional Rating</label>
							<div class="p-col-12 p-md-9">
								<p-rating id="frating" formControlName="func"></p-rating>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12 p-md-12">
							<div class="pt-2 text-right">
								<button pButton pRipple type="submit" [disabled]="reviewForm?.invalid" label="Submit" icon="pi pi-save" class="p-button-raised p-mr-2 p-mb-2"></button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<p-footer>
				<!--<button type="button" pButton icon="pi pi-check" (click)="displayReview=false" label="Yes" class="p-button-text"></button>
				<button type="button" pButton icon="pi pi-times" (click)="displayReview=false" label="Close Dialog" class="p-button-text"></button>-->
			</p-footer>
		</p-dialog>
		<p-dialog header="We need your feedback" [(visible)]="displayFeedback" modal="modal" showEffect="fade" [style]="{width: '500px'}">
			<div style="line-height: 1.5">
				<form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()">
					<div class="p-fluid">		
						<div class="p-field p-grid">
							<label for="head" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Feedback Type</label>
							<div class="p-col-12 p-md-9">
								<p-dropdown inputId="head" [options]="feedbackTypes" [(ngModel)]="selectedFeedback" placeholder="Select Feedback Type" [showClear]="true" [filter]="true" formControlName="feedbackType">
								</p-dropdown>
							</div>
						</div>
						<div class="p-field p-grid">
							<label for="desc" class="p-col-12 p-mb-2 p-md-3 p-mb-md-0">Description</label>
							<div class="p-col-12 p-md-9">
								<textarea id="desc" type="text" pInputText placeholder="Description" formControlName="description"></textarea>
							</div>
						</div>
					</div>
					<div class="p-grid">
						<div class="p-col-12 p-md-12">
							<div class="pt-2 text-right">
								<button pButton pRipple type="submit" [disabled]="feedbackForm?.invalid" label="Submit" icon="pi pi-save" class="p-button-raised p-mr-2 p-mb-2"></button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<p-footer>
				<!--<button type="button" pButton icon="pi pi-check" (click)="displayFeedback=false" label="Yes" class="p-button-text"></button>
				<button type="button" pButton icon="pi pi-times" (click)="displayFeedback=false" label="Close Dialog" class="p-button-text"></button>-->
			</p-footer>
		</p-dialog>
		<p-dialog header="A list of manuals" [(visible)]="displayManuals" modal="modal" showEffect="fade" [style]="{width: '400px'}">
			<div style="line-height: 1.5">
				<div class="card widget-download-files">
					<div class="card-header">
						<div class="title">
							<h5>Download Files</h5>
						</div>
						<div class="files-info">
							<span>Download All</span>
						</div>
					</div>
					<ul>
						<li *ngFor="let manual of manuals">
							<div class="p-grid list-item">
								<div class="p-col-2 icon">
									<img src="assets/layout/images/dashboard/icon-sapphire.svg" alt="mirage-layout" />
								</div>
								<div class="p-col-10 file-name">
									<h6 class="name"><a [href]="manual?.url" [download]="manual?.name">{{manual?.name}}</a></h6>
								</div>
							</div>		
						</li>
					</ul>
				</div>
			</div>
			<p-footer>
				<!--<button type="button" pButton icon="pi pi-check" (click)="displayManuals=false" label="Yes" class="p-button-text"></button>
				<button type="button" pButton icon="pi pi-times" (click)="displayManuals=false" label="Close Dialog" class="p-button-text"></button>-->
			</p-footer>
		</p-dialog>
		<p-dialog header="Privacy Policy" [(visible)]="displayPrivacyPolicy" modal="modal" showEffect="fade" [style]="{width: '400px'}">
			<div style="line-height: 1.5">
				Using this app means that you agree that any information collected while using the application can be used to 
				implement things related to business intelligence operations.
			</div>
			<p-footer>
				<!--<button type="button" pButton icon="pi pi-check" (click)="displayPrivacyPolicy=false" label="Yes" class="p-button-text"></button>
				<button type="button" pButton icon="pi pi-times" (click)="displayPrivacyPolicy=false" label="Close Dialog" class="p-button-text"></button>-->
			</p-footer>
		</p-dialog>
        <div class="layout-topbar">
			<div class="layout-topbar-wrapper">
                <div class="layout-topbar-left">
					<div class="layout-topbar-logo-wrapper">
						<a href="#" class="layout-topbar-logo">
							<img src="assets/layout/images/logo-mirage@2x.png" alt="halogen" />
							<span class="app-name">Halo Biz</span>
						</a>
					</div>
					<a href="#" class="sidebar-menu-button" (click)="app?.onMenuButtonClick($event)">
						<i class="pi pi-bars"></i>
					</a>
					<a href="#" class="megamenu-mobile-button d-none" (click)="app?.onMegaMenuMobileButtonClick($event)">
						<i class="pi pi-align-right megamenu-icon"></i>
					</a>
					<a href="#" class="topbar-menu-mobile-button" (click)="app?.onTopbarMobileMenuButtonClick($event)">
						<i class="pi pi-ellipsis-v"></i>
					</a>
					<div class="layout-megamenu-wrapper">
						<a class="layout-megamenu-button" href="javascript:void(0)" (click)="app?.onMegaMenuButtonClick($event)">
							<i class="pi pi-comment"></i>
							Help Menu
						</a>
						<ul class="layout-megamenu" [ngClass]="{'layout-megamenu-active fadeInDown': app?.megaMenuActive}"
                            (click)="app.onMegaMenuClick($event)">
							<li [ngClass]="{'active-topmenuitem': activeItem === 1}" (click)="mobileMegaMenuItemClick(1)">
								<a href="#">Internal <i class="pi pi-angle-down"></i></a>
								<ul>
									<li class="active-row">
										<i class="pi pi-circle-on"></i>
										<span (click)="displayReview=true">
                                        <h5>App Review</h5>
                                        <span>Give this app a review</span>
                                    </span>
									</li>
									<li>
										<i class="pi pi-circle-on"></i>
										<span (click)="displayFeedback=true">
                                        <h5>Suggest Feedback</h5>
                                        <span>We appreciate your feedback</span>
                                    </span>
									</li>
									<li>
										<i class="pi pi-circle-on"></i>
										<span (click)="displayManuals=true">
                                        <h5>User Manual</h5>
                                        <span>Application user manuals</span>
                                    </span>
									</li>
								</ul>
							</li>
							<li [ngClass]="{'active-topmenuitem': activeItem === 2}" (click)="mobileMegaMenuItemClick(2)">
								<a href="#">Policies <i class="pi pi-angle-down"></i></a>
								<ul>
									<li>
										<i class="pi pi-circle-on"></i>
										<span (click)="displayPrivacyPolicy=true">
                                        <h5>Privacy Policy</h5>
                                        <span>Learn more about our policies</span>
                                    </span>
									</li>
								</ul>
							</li>
						</ul>
					</div>
                </div>
                <div class="layout-topbar-right fadeInDown">
					<ul class="layout-topbar-actions">
						<li #search class="search-item topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === search}">
							<a href="#" class="topbar-search-mobile-button" (click)="app.onTopbarItemClick($event,search)">
								<i class="topbar-icon pi pi-search"></i>
							</a>
							<ul class="search-item-submenu fadeInDown" (click)="app.topbarItemClick = true">
								<li>
                                    <span class="md-inputfield search-input-wrapper">
                                        <input pInputText placeholder="Search..."/>
                                        <i class="pi pi-search"></i>
                                    </span>
                                </li>
                            </ul>
                        </li>
						<li #calendar class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === calendar}">
							<a href="#" (click)="app.onTopbarItemClick($event,calendar)">
								<i class="topbar-icon pi pi-calendar"></i>
							</a>
							<ul class="fadeInDown" (click)="app.topbarItemClick = true">
								<li class="layout-submenu-header">
									<h1>Calendar</h1>
								</li>
								<li class="calendar">
                                    <p-calendar [inline]="true"></p-calendar>
								</li>
							</ul>
						</li>
						<li #message class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === message}">
							<a href="#" (click)="app.onTopbarItemClick($event,message)">
								<span class="p-overlay-badge">
									<i class="topbar-icon pi pi-inbox"></i>
									<span class="p-badge">{{userDeliverableInWorkBench}}</span>
								</span>							
							</a>
							<ul class="fadeInDown">
								<li class="layout-submenu-header">
									<h1>Open Task</h1>
									<span>{{userDeliverableInWorkBench}} open task</span>
								</li>
								<li class="layout-submenu-item">
									<div class="menu-text">
										<p></p>
										<span></span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
							</ul>
						</li>
						<li #gift class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === gift}">
							<a href="#" (click)="app.onTopbarItemClick($event,gift)">
								<span class="p-overlay-badge">
									<i class="topbar-icon pi pi-envelope"></i>
									<span class="p-badge">{{completedDeliverable}}</span>
								</span>	
							</a>
							<ul class="fadeInDown">
								<li class="layout-submenu-header">
									<h1>Closed Task</h1>
									<span>{{completedDeliverable}} closed task</span>
								</li>
								<li class="layout-submenu-item">
									<div class="menu-text">
										<p></p>
										<span></span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
							</ul>
						</li>
						<li #profile class="topbar-item profile-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}">
							<a href="#" (click)="app.onTopbarItemClick($event,profile)">
                            <span class="profile-image-wrapper">
                                <img src="{{authUser?.imageUrl}}" alt="Halogen" />
                            </span>
								<span class="profile-info-wrapper">
                                <h3>{{authUser?.firstName}} {{authUser?.lastName}}</h3>
                                <span></span>
                            </span>
							</a>
							<ul class="profile-item-submenu fadeInDown">
								<li class="profile-submenu-header">
									<div class="performance">
										<span>Task Stat</span>
										<img src="assets/layout/images/topbar/asset-bars.svg" alt="halogen" />
									</div>
									<div class="profile">
										<img src="{{authUser?.imageUrl}}" alt="halogen"
														width="40" />
                                        <h1>{{authUser?.firstName}} {{authUser?.lastName}}</h1>
										<span></span>
									</div>
								</li>
								<li class="layout-submenu-item">
									<i class="pi pi-list icon icon-1"></i>
									<div class="menu-text">
										<p>Tasks</p>
										<span>0</span>
									</div>
								</li>
								<li class="layout-submenu-item">
								<i class="pi pi-list icon icon-1"></i>
									<div class="menu-text">
										<p>Completed</p>
										<span>0</span>
									</div>
								</li>
								<li class="layout-submenu-item">
								<i class="pi pi-list icon icon-1"></i>
									<div class="menu-text">
										<p>Pending</p>
										<span>0</span>
									</div>
								</li>
								<li class="layout-submenu-footer">
									<button class="signout-button" (click)="signOut()">Sign Out</button>
									<button class="signout-button" routerLink="/home/profile">Profile</button>
								</li>
							</ul>
						</li>
						<li>
							<a href="#" class="layout-rightpanel-button" (click)="app.onRightPanelButtonClick($event)">
								<i class="pi pi-arrow-left"></i>
							</a>
						</li>
                    </ul>
					<ul class="profile-mobile-wrapper">
						<li #mobileProfile class="topbar-item profile-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === mobileProfile}">
							<a href="#" (click)="app.onTopbarItemClick($event,mobileProfile)">
                            <span class="profile-image-wrapper">
                                <img src="{{authUser?.imageUrl}}" alt="halogen" />
                            </span>
								<span class="profile-info-wrapper">
                                <h3>{{authUser?.firstName}} {{authUser?.lastName}}</h3>
                                <span></span>
                            </span>
							</a>
							<ul class="fadeInDown">
								<li class="profile-submenu-header">
									<div class="performance">
										<span>Task Stat</span>
										<img src="assets/layout/images/topbar/asset-bars.svg" alt="halogen" />
									</div>
									<div class="profile">
										<img src="{{authUser?.imageUrl}}" alt="halogen" width="45" />
                                        <h1>{{authUser?.firstName}} {{authUser?.lastName}}</h1>
										<span></span>
									</div>
								</li>
								<li>
									<i class="pi pi-list icon icon-1"></i>
									<div class="menu-text">
										<p>Tasks</p>
										<span>0</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li>
								<i class="pi pi-list icon icon-1"></i>
									<div class="menu-text">
										<p>Completed</p>
										<span>0</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li>
								<i class="pi pi-list icon icon-1"></i>
									<div class="menu-text">
										<p>Pending</p>
										<span>0</span>
									</div>
									<i class="pi pi-angle-right"></i>
								</li>
								<li class="layout-submenu-footer">
									<button class="signout-button">Sign Out</button>
								</li>
							</ul>
						</li>
					</ul>
                </div>
            </div>
        </div>
    `
})
export class AppTopBarComponent implements OnInit{

    activeItem: number;
    authUser: User;
	exampleProcesss = "This is a good point";
	completedDeliverable: number = 0;
	userDeliverableInWorkBench: number = 0;
	currentlyLoggedInUser: any;

    constructor(public app: AppMainComponent,
				//public deliverableFulfillmentService: DeliverableFulfillmentService,
				//public appReviewService: AppReviewService,
				//public appFeedbackService: AppFeedbackService,
                public fireBaseAuthService: FireBaseAuthService,
                public idle: Idle,
                public userService: UserService,
                public messageService: MessageService,
				private formBuilder: FormBuilder
                ) {
        this.authUser = {
            firstName: '',
            lastName: '',
            imageUrl: '',
            email: '',
        };
    }

    mobileMegaMenuItemClick(index) {
        this.app.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
    }

    signOut() {
        this.fireBaseAuthService.logout();
    }

	displayReview: boolean = false;
	displayFeedback: boolean = false;
	displayManuals: boolean = false;
	displayPrivacyPolicy: boolean = false;

	applicationModules: SelectItem[] = [
		{ label: 'Self-Service', value: 'Self-Service'},
		{ label: 'Setups', value: 'Setups'},
		{ label: 'LAMS', value: 'LAMS'},
		{ label: 'Finance', value: 'Finance'}
	]

	selectedModule: any;

	feedbackTypes: SelectItem[] = [
		{ label: 'Application Defect', value: 'Application Defect'},
		{ label: 'Bug Report', value: 'Bug Report'}
	]

	selectedFeedback: any;

	reviewForm: FormGroup;
	feedbackForm: FormGroup;

	manuals: any[] = [
		{ name: 'Client Account Management', url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' },
		{ name: 'Lead Administration And Sales', url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' }
	];

    ngOnInit(): void {
        console.log('The top bar init is running');
		console.log('The timeout is restarted.')
        this.idle?.watch();
        this.authUser = this.fireBaseAuthService?.authUserProfile;
		this.getCurrentloggedInUser(this.authUser);
		
		// this.deliverableFulfillmentService.getDeliverableDashboard(this.authUser?.id).subscribe(async (res: any) => {
		// 	this.completedDeliverable = res?.completedDeliverable
		// 	this.userDeliverableInWorkBench = res?.userDeliverableInWorkBench
		// })

		this.reviewForm = this.formBuilder.group({
			module: ['', Validators.required],
			uiux: [0, Validators.required],
			func: [0, Validators.required]
		})

		this.feedbackForm = this.formBuilder.group({
			feedbackType: [null, Validators.required],
			description: ['', Validators.required],
			document: ['']
		})
    }

	submitReview(){

		const reviewForm = this.reviewForm.value;

		const formData = {
			module: reviewForm.module,
			lookAndFeelRating: reviewForm.uiux,
			functionalityRating: reviewForm.func
		}

		this.messageService.add({severity:'info', summary: 'Notice',
        	detail:'Submitting app review'});

		// this.appReviewService.postAppReview(formData).subscribe(async (res:any) => {
		// 	this.messageService.add({severity:'success', summary: 'App review posted', detail:'record added'});
		// 	this.reviewForm.reset();
		// })

		this.displayReview = false;
	}

	getCurrentloggedInUser(user){
		this.currentlyLoggedInUser = user
		console.log("this is the authUser ",this.currentlyLoggedInUser);
		return this.currentlyLoggedInUser;
	}

	submitFeedback(){
		const feedbackForm = this.feedbackForm.value;

		const formData = {
			feedbackType: feedbackForm.feedbackType,
			description: feedbackForm.document,
			documentsUrl: feedbackForm.document
		}

		this.messageService.add({severity:'info', summary: 'Notice',
        	detail:'Submiting app feedback'});

		// this.appFeedbackService.postAppFeedback(formData).subscribe(async (res:any) => {
		// 	this.messageService.add({severity:'success', summary: 'App feedback posted', detail:'record added'});
		// 	this.feedbackForm.reset();
		// })

		this.displayFeedback = false;
	}
}
