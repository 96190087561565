import { Injectable } from '@angular/core';
import { CommonResponse } from '../interface/home';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConvergenceAdvisory, ConvergenceCategory, EventType } from '../interface/convergence';
import { GetReverseGeocodingLocationRespVM } from '../interface/employment';
import { environment } from 'src/environments/environment';
import { ApiLoadingService } from './api-loading.service';
import { FireBaseAuthService } from './fire-base-auth.service';
@Injectable({
  providedIn: 'root'
})
export class ResponsePanicService {
responseTeam:string = "ResponseTeams";
callReport:string = "CallReport";
haloSOS:string = "HaloSos";
haloSOSAssignment:string = "HaloSosAssignment";
incidentCapture:string = "IncidentCapture";
sitrep:string = "Sitrep";
sitrepChecklist:string = "SitrepChecklist";

 baseUrl2 = environment.baseUrl;
 baseUrl = environment.haloSOSBaseUrl;
  //baseUrl2 = "https://localhost:44344/api/v1/";
  //baseUrl = "https://localhost:7075/api/";;
  baseUrl_ = environment.gmaBaseUrl  ;
 //baseUrl_ = environment.gmaBaseUrl + "Convergence/";
  //baseUrl = environment.gmaBaseUrl + "Employment/";
  constructor(private httpClient: HttpClient, 
    private fireBaseAuthService: FireBaseAuthService,) { }
   
      allResponseTeams(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ResponseTeams/GetAllResponseTeams"
    );
  }

  allResponseTeamsForAssignment(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
  
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SOSAssignment/GetAllResponseTeamsForAssignment"
    );
  }
  //HaloSOS
  allHaloSOS(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + `${this.haloSOS}/GetAllHaloSOS`
    );
  }
    allAttendedSOS(): Observable<CommonResponse> {
    //   const token = this.fireBaseAuthService.token;
    // const refreshToken = this.fireBaseAuthService.refreshToken;
    // const headers = {
    //   Accept: "application/json",
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    //   "xr-token": `${refreshToken}`,
    //   "Cache-Control": "no-cache",
    //   Pragma: "no-cache",
    //   Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    // };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + `${this.haloSOS}/GetAllAttendedSOS`
    );
  }
    allUnattendedSOS(): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + `${this.haloSOS}/GetAllUnattendedSOS`
    );
  }
    allAssignedSOS(): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + `${this.haloSOS}/GetAllAssignedSOS`
    );
  }
    getHaloSOS(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + `${this.haloSOS}/GetHaloSOSById/${id}`
    );
  }
   
  allHaloSOSDashboadItem(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + `${this.haloSOS}/GetHaloSOSDashboardItems`
    );
  }
   postHaloSOS(data: any) {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
   
    return this.httpClient.post(
      this.baseUrl + "HaloSos/AddNewHaloSOS",
      data
    );
  }
  deleteHaloSOS(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSos/DeleteHaloSOS/" + id
    );
  }
  setHaloSOSAsDealtWithBySOSId(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSos/SetHaloSOSAsDealtWithBySOSId/" + id
    );
  }
   setHaloSOSAsClosedBySOSId(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSos/SetHaloSOSAsClosedBySOSId/" + id
    );
  }
  

  //Assignment
  allHaloSOSAssignment(): Observable<CommonResponse> {
    
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SOSAssignment/GetAllHaloSOSAssignments"
    );
  }
    getHaloSOSAsssignment(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SOSAssignment/GetHaloSOSAssignmentById/" + id
    );
  }
    getHaloSOSAssignmentByHaloSOSId(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SOSAssignment/GetHaloSOSAssignmentByHaloSOSId/" + id
    );
  }

    getAllNotDetachedHaloSOSAssignmentByHaloSOSId(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SOSAssignment/GetAllNotDetachedSOSAssignmentByHaloSOSId/" + id
    );
  }

  getAllResponseTeamsByHaloSOSId(id: any): Observable<CommonResponse> {
      
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SOSAssignment/GetAllResponseTeamsByHaloSOSId/" + id
    );
  }

   getHaloSOSAssignmentToLeadProfileId(id: any): Observable<CommonResponse> {
     
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "SOSAssignment/GetHaloSOSAssignmentToTeamLeadByProfileId/" + id
    );
  }
   
   postHaloSOSAssignment(data: any) {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.post(
      this.baseUrl + "SOSAssignment/AddNewHaloSOSAssignment",
      data
    );
  }
   releaseTeamsAssignedbyTeamId(data: any) {
   
    return this.httpClient.post(
      this.baseUrl + "SOSAssignment/ReleaseTeamsByTeamId",
      data
    );
  }
  deleteHaloSOSAssignment(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SOSAssignment/DeleteHaloSOSAssignment/" + id
    );
  }
   setHaloSOSAssignmentAsAccepted(id: any, profileId) {
    return this.httpClient.delete(
      this.baseUrl + "SOSAssignment/SetHaloSOSAssignmentAsAcceptedByAssignmentId/" + id + "/" + profileId
    );
  }
  SetHaloSOSAssignmentAsRejected(data) {
    return this.httpClient.post(
      this.baseUrl + "SOSAssignment/SetHaloSOSAssignmentAsRejected", data
    );
  }
   setHaloSOSAssignmentAsStarted(id: any, profileId) {
    return this.httpClient.delete(
      this.baseUrl + "SOSAssignment/SetHaloSOSAssignmentAsStartedByAssignmentId/" + id + "/" + profileId
    );
  }
   setHaloSOSAssignmentAsStopped(id: any, profileId) {
    return this.httpClient.get(
      this.baseUrl + "SOSAssignment/SetHaloSOSAssignmentAsStoppedByAssignmentId/" + id + "/" + profileId
    );
  }
  setHaloSOSAssignmentAsDetached(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SOSAssignment/SetHaloSOSAssignmentAsDetachedByAssignmentId/" + id
    );
  }
   updateHaloSOSAssignment(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "SOSAssignment/UpdateHaloSOSAssignment/" + id,
      data
    );
  }
    ReleaseTeamBySOSId(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "SOSAssignment/ReleaseTeamByHaloSOSId/" + id
    );
  }

  GetAllEventTypes(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(this.baseUrl + "IncidentCapture/GetAllEventTypes");
  }
   GetAllAdvisories(): Observable<ConvergenceAdvisory[]> {
    return this.httpClient.get<ConvergenceAdvisory[]>(
      this.baseUrl_ + "GetAllAdvisories"
    );
  }

   GetReverseGeocodingLocation(
    latitude: number,
    longititude: number
  ): Observable<GetReverseGeocodingLocationRespVM> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<GetReverseGeocodingLocationRespVM>(
      this.baseUrl_ +
        "Employment/GetReverseGeocodingLocation/" +
        latitude +
        "/" +
        longititude, {headers}
    );
  }

  GetGeocodingLocation(
    latitude: number,
    longititude: number
  ): Observable<GetReverseGeocodingLocationRespVM> {
    
    return this.httpClient.get<GetReverseGeocodingLocationRespVM>(
      this.baseUrl_ +
        "LocationsAndTracking/GetLocationAddress/" +
        latitude +
        "/" +
        longititude
    );
  }

   GetAllConvergenceCategories(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "IncidentCapture/GetAllConvergenceCategories"
    );
  }
   allCustomerDivision(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(this.baseUrl + "IncidentCapture/GetAllCustomerDivisions", {headers});
  }

   allClientBeneficiaryData(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(this.baseUrl + "IncidentCapture/ClientBeneficiary");
  }
   getResponseTeamResourceAttachment(id: any): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ResponseTeams/GetAllResouceAttachmentsByTeamId/" + id
    );
  }
  allResponseTeamRestionStationMaps(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "ResponseTeams/GetAllResponseTeamRestingStationMaps"
    );
  }

  vehicleTracking(id): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "LocationsAndTracking/GetTrackingByIMEI/" + id
    );
  }
}
