import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { HalobizLoginComponent } from './pages/halobiz-login/halobiz-login.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppMainComponent) {}

    ngOnInit() {
        this.model = [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/home/response-panic']},
            
              {
                
                label: 'Response', icon: 'pi pi-fw pi-star', routerLink: ['/home/profile'],
                items: [
                    {label: 'Panic Dashboard', icon: 'pi pi-fw pi-id-card', routerLink: ['/home/response-panic']},
                    {label: 'Capture Incident', icon: 'pi pi-fw pi-id-card', routerLink: ['/home/capture-incident']},
                    {label: 'Start Response Trip', icon: 'pi pi-fw pi-id-card', routerLink: ['/home/team-lead-start']},
                ]
            },
            
            // {
            //     label: 'Docs', icon: 'pi pi-fw pi-file', routerLink: ['/documentation']
            // },
            // {
            //     label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
            // }
        ];
    }

    onMenuClick() {
        this.app.menuClick = true;
    }
}
