import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../interface/home';
import { FireBaseAuthService } from './fire-base-auth.service';

@Injectable({
  providedIn: 'root'
})
export class HaloReportService {
 baseUrl = environment.haloSOSBaseUrl + "CallReport/";
 baseUrlSitrep = environment.haloSOSBaseUrl + "Sitrep/";
 baseUrlSitrepList = environment.haloSOSBaseUrl + "SitrepChecklist/";
  //baseUrl = "https://localhost:7075/api/CallReport/";
  //baseUrlSitrep = "https://localhost:7075/api/Sitrep/";
  //baseUrlSitrepList = "https://localhost:7075/api/CallReport/";
  //baseUrl = "https://localhost:44344/api/SitrepChecklist/";
  constructor(private httpClient: HttpClient, 
    private fireBaseAuthService: FireBaseAuthService,) { }

  
  //HaloSOSReport
  allHaloSOSCallReport(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllHaloSOSCallReports"
    );
  }
  //get call rep
    getHaloSOSCallReport(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetHaloSOSCallReportById/" + id
    );
  }
   getAllHaloSOSCallReportBySOSId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "GetAllHaloSOSCallReportsBySOSId/" + id
    );
  }
   
   postHaloSOSCallReport(data: any) {
        const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.post(
      this.baseUrl + "AddNewHaloSOSCallReport",
      data, {headers}
    );
  }
  deleteHaloSOSCallReport(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "DeleteHaloSOSCallReport/" + id
    );
  }
  
   updateHaloSOSCallReport(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "UpdateHaloSOSCallReport/" + id,
      data
    );
  }

   //HaloSOS sitrep
  allHaloSOSSitreps(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrep + "GetAllHaloSOSSitreps"
    );
  }
    getHaloSOSSitrep(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrep + "GetHaloSOSSitrepById/" + id
    );
  }
   getAllHaloSOSSitrepBySOSId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrep + "GetAllHaloSOSSitrepsBySOSId/" + id
    );
  }

   getAllHaloSOSSitrepBySOSIdAndProfileId(id: any, profileId:any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrep + "GetAllHaloSOSSitrepsBySOSIdAndProfileId/" + id + "/" + profileId
    );
  }
          
   postHaloSOSSitrep(data: any) {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.post(
      this.baseUrlSitrep + "AddNewHaloSOSSitrep",
      data, {headers}
    );
  }
  deleteHaloSOSSitrep(id: any) {
    return this.httpClient.delete(
      this.baseUrlSitrep + "DeleteHaloSOSSitrep/" + id
    );
  }
  
   updateHaloSOSSitrep(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrlSitrep + "UpdateHaloSOSSitrep/" + id,
      data
    );
  }

    //HaloSOS checklist
  allHaloSOSSitrepsChecklist(): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrepList + "GetAllHaloSOSSitrepChecklists"
    );
  }
    getHaloSOSSitrepChecklist(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrepList + "GetHaloSOSSitrepChecklistById/" + id
    );
  }
   getAllHaloSOSSitrepChecklistBySOSId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrepList + "GetAllHaloSOSSitrepChecklistsBySOSId/" + id
    );
  }
  getAllHaloSOSSitrepChecklistBySitrepId(id: any): Observable<CommonResponse> {
    return this.httpClient.get<CommonResponse>(
      this.baseUrlSitrepList + "GetAllHaloSOSSitrepChecklistsBySitrepId/" + id
    );
  }
   postHaloSOSSitrepChecklist(data: any) {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.post(
      this.baseUrlSitrepList + "AddNewHaloSOSSitrepChecklist",
      data, {headers}
    );
  }
  deleteHaloSOSSitrepChecklist(id: any) {
    return this.httpClient.delete(
      this.baseUrlSitrepList + "DeleteHaloSOSSitrepChecklist/" + id
    );
  }
  
   updateHaloSOSSitrepChecklist(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrlSitrepList + "UpdateHaloSOSSitrepChecklist/" + id,
      data
    );
  }
}
