import { HaloSOSAssignmentTeamLead, HaloSOSSitrep } from './../../interface/response';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonResponse } from 'src/app/interface/home';
import { AllResourceAttachmentsforTeamLeadPage, HaloSOS, HaloSOSAssignment } from 'src/app/interface/response';
import { HaloReportService } from 'src/app/services/halo-report.service';
import { ResponsePanicService } from 'src/app/services/response-panic.service';

@Component({
  selector: 'app-team-lead-start',
  templateUrl: './team-lead-start.component.html',
  styleUrls: ['./team-lead-start.component.scss']
})
export class TeamLeadStartComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();

  haloSOS:HaloSOS[];
selectedHaloSOS:HaloSOS;
  haloSOSSitrep:HaloSOSSitrep[];
selectedHaloSOSSitrep:HaloSOSSitrep;
selectedAllResourceAttachmentsforTeamLead:AllResourceAttachmentsforTeamLeadPage[];
loading:boolean;
start ;
stopped;
isAccepted;
isRejected = false;
haloSOSId;
isShowJourneyStart:boolean;
isShowSitrepFormAndStop:boolean;
isShowReject:boolean;
haloSOSAssignment:HaloSOSAssignment[];
selectedHaloSOSAssignment:HaloSOSAssignment;
haloSOSAssignmentTeamLead:HaloSOSAssignmentTeamLead[];
selectedHaloSOSAssignmentTeamLead:HaloSOSAssignmentTeamLead;
isTrackingDialog = false;
haloAssignmentId:number;
 public haloSitrepForm: FormGroup;
 public assignmentRejectForm: FormGroup;
 public haloSitrepChecklistForm: FormGroup;
  profileId: any;

  constructor(
    private responsePanicService: ResponsePanicService,
    private haloReportService: HaloReportService,
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.assignmentRejectForm = this.formBuilder.group({
      sOSAssignmentId: [null, [Validators.required]],
      profileId: [this.profileId, [Validators.required]],
      reasonForReject: ["", [Validators.required]],
    });
    this.haloSitrepForm = this.formBuilder.group({
      haloSOSId: [this.haloSOSId, [Validators.required]],
      comment: ["", [Validators.required]],
    });
     this.haloSitrepChecklistForm = this.formBuilder.group({
      haloSOSId: [this.haloSOSId, [Validators.required]],
      responseTeamId: [null, [Validators.required]],
    });
    this.getProfileId();
     this.fetchHaloSOSByProfileId(
      this.profileId
    );

  }

  fetchHaloSOSByProfileId(id: any) {
    this.loading = true;
    this.responsePanicService
      .getHaloSOSAssignmentToLeadProfileId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            console.log(res);
            this.haloAssignmentId = res.id;
            this.isAccepted = res.isAccepted;
            this.stopped = res.isStopped;
            this.start = res.isStarted;
            this.haloSOSId = res.haloSOSId;
            console.log("halososid", this.haloSOSId);
            this.haloSitrepForm.patchValue({
              haloSOSId: this.haloSOSId
            });
            //this.fetchAllSitrepsBySOSIdAndProfileId(this.haloSOSId, this.profileId);
            this.fetchAllSitrepsBySOSId(this.haloSOSId);
            console.log(this.haloAssignmentId);
            const myArray = [];
            myArray.push(res);
            if(res.haloSOSId === 0 || res === null){
              this.haloSOSAssignment = [];
              return;
            }
             if(this.isAccepted === true && this.stopped === false && this.start === false){
              this.isShowJourneyStart = true;
            }
             if(this.isAccepted === true && this.stopped === false && this.start === true){
              this.isShowSitrepFormAndStop = true;
            }
            this.haloSOSAssignment = myArray;
            console.log(this.haloSOSAssignment);
            this.loading = false;
          }
         
        },
        (error) => {
          this.loading = false;
          this.connectionError();
        }
      );
    this.loading = false;
  }
 async SubmitSituationReport() {
     this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding sitrep",
    });
       const formData = this.haloSitrepForm.value;
        const postData = {
           haloSOSId: formData.haloSOSId,
            comment: formData.comment,
        };
        this.haloReportService
      .postHaloSOSSitrep(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Sitrep added",
            });
            //this.haloSitrepForm.reset();
            this.haloSitrepForm.patchValue({
              comment: "",
            })
            this.fetchAllSitrepsBySOSId(this.haloSOSId);
            //this.resourceAttachmentForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          //this.submittingData = false;
        }
      );
  }
   acceptHaloSOSAssignment(haloSOSAssignment: HaloSOSAssignment): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to accept this SOS ticket?",
      accept: () => {
        this._acceptHaloSOSAssignment(haloSOSAssignment);
      },
    });
  }
  _acceptHaloSOSAssignment(haloSOSAssignment) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Accepting...",
    });
    this.responsePanicService.setHaloSOSAssignmentAsAccepted(haloSOSAssignment.id,this.profileId).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Accepted",
          detail: "You have accepted this ticket successfully",
        });
        this.isShowJourneyStart = true;
        this.isAccepted = true;
        //await this.fetchAllArmedEscortServiceAssignmentDetails();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  showRejectModal(haloSOSAssignment: HaloSOSAssignment){
    this.isShowReject = true;
    this.selectedHaloSOSAssignment = haloSOSAssignment;
    this.assignmentRejectForm.patchValue({
      sOSAssignmentId: haloSOSAssignment.id,
      profileId: this.profileId,
    })
  }
  rejectHaloSOSAssignment(): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to reject this SOS ticket?",
      accept: () => {
        this._rejectHaloSOSAssignment();
      },
    });
  }
  _rejectHaloSOSAssignment() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Rejection in process...",
    });
    const formData = this.assignmentRejectForm.value;
    const postData = {
      sOSAssignmentId: formData.sOSAssignmentId,
        profileId: this.profileId,
        reasonForReject: formData.reasonForReject,
    };
    this.responsePanicService.SetHaloSOSAssignmentAsRejected(postData).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Rejected",
          detail: "You have rejected this ticket successfully",
        });
        // this.fetchHaloSOSByProfileId(
        //   this.profileId
        // );
        this.isRejected = true;
        const formData = this.assignmentRejectForm.value;
        this.assignmentRejectForm.patchValue({
          sOSAssignmentId: formData.sOSAssignmentId,
          reasonForReject: "",
        });
       
        this.isShowReject = false;
       
        //await this.fetchAllArmedEscortServiceAssignmentDetails();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
   startJourney(): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to start trip?",
      accept: () => {
        //this._acceptHaloSOSAssignment(resourceAttachmentsforTeamLead);
        this._startJourney();
      },
    });
  }
  _startJourney() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Starting journey...",
    });
    this.responsePanicService.setHaloSOSAssignmentAsStarted(this.haloAssignmentId, this.profileId).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Statred",
          detail: "You have started journey",
        });
        this.isShowJourneyStart = false;
        this.isShowSitrepFormAndStop = true;
        //await this.fetchAllArmedEscortServiceAssignmentDetails();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

     stopJourney(): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to stop trip?",
      accept: () => {
        //this._acceptHaloSOSAssignment(resourceAttachmentsforTeamLead);
        this._stopJourney();
      },
    });
  }
  _stopJourney() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Stopping journey...",
    });
    this.responsePanicService.setHaloSOSAssignmentAsStopped(this.haloAssignmentId, this.profileId).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Statred",
          detail: "You have started journey",
        });
        this.isShowJourneyStart = false;
        this.isShowSitrepFormAndStop = true;
        window.location.reload();
        //await this.fetchAllArmedEscortServiceAssignmentDetails();
      },
      (error) => {
        this.connectionError();
      }
    );
  }

 
  getProfileId() {
    let result = JSON.parse(sessionStorage.getItem("userProfile"));
    sessionStorage.setItem("id", result.id);
    this.profileId = sessionStorage.getItem("id");
  }

   fetchAllSitrepsBySOSId(id) {
    this.haloReportService
      .getAllHaloSOSSitrepBySOSId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.haloSOSSitrep = res;
          //console.log("call rep", this.haloSOSCallReport);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }

   fetchAllSitrepsBySOSIdAndProfileId(id, profileId) {
    this.haloReportService
      .getAllHaloSOSSitrepBySOSIdAndProfileId(id, profileId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.haloSOSSitrep = res;
          //console.log("call rep", this.haloSOSCallReport);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }
   private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
  //  showAssignmentDialog(haloSOS: HaloSOS){
  //   this.isAssignmentDialog = true;
  //   this.haloAssignmentForm.patchValue({
  //     haloSOSId: haloSOS.id,
  //   })
  // }

}
