// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  dev: true,
  gmaBaseUrl: 'https://dev-gma.halobizapps.com/api/v1/',
  haloSOSBaseUrl: 'https://dev-sos.halobizapps.com/api/',
  baseUrl: "https://dev-halobiz.halobizapps.com/api/v1/",
  //baseUrl: "https://localhost:44344/api/v1/",
  reportUrl: "https://report.halobizapps.com/Reports/",
  identityBaseUrl: "https://dev-halobiz.halobizapps.com/api/v1/",
  onlineBackendBaseUrl: "https://dev-online.halobizapps.com/api/v1/",
  onlineBackendBaseUrl_: "https://dev-online.halobizapps.com/api/",
  schedulerBaseUrl: "https://dev-scheduler.halobizapps.com/api/v1/",
  baseMailUrl: "http://mail:5000/Mail/",
  frontednUrl: "https://halobiz-dev.halobizapps.com/",
  armadaBaseUrl: "https://dev-halobiz.halobizapps.com/api/v1/",
  testProjectAll: "https://dev-halobiz.halobizapps.com/api/v1/",
  liabilityControlAccountId: "1200000000", //Assets::Receivable
  assetControlAccountId: "5100000000", //Expenses::PayrollAccount
  expenseControlAccountId: "1300000000", //Specific Control Account To Be Created
  defaultImage: "/assets/layout/images/users-vector.jpg",
  defaultServiceImage:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FGuard.png?alt=media&token=2c6c2279-5508-4d8a-8169-a468595bb2f6",
  amarda:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAMARDA.jpeg?alt=media&token=d29b3f37-4060-4e41-b99b-942d7518098f",
  "physical security":
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FPS.jpeg?alt=media&token=4cfbec59-64c8-4dfe-9950-59b7a17b4c25",
  academy:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FACADEMY.jpeg?alt=media&token=825a9119-2bc5-452c-83a5-f10afbc94aeb",
  avant:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAVANT.jpeg?alt=media&token=46908f3c-a5c8-4e25-8739-fc8f7813a814",
  avert:
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAVERT.jpeg?alt=media&token=6e3f1302-bc95-493c-b65c-139a8f20e7c3",
  "armour x":
    "https://firebasestorage.googleapis.com/v0/b/halo-biz.appspot.com/o/serviceImage%2FAMOUR-X.jpeg?alt=media&token=b5865ace-0878-4eaa-a69f-28a7a1b4cbf9",
  reportViewerHost:
    "https://reportserver.northeurope.cloudapp.azure.com/reports/report/",

  endorsementType: {
    New: 1,
    ServiceAddition: 2,
    ServiceTopUp: 3,
    ServiceReduction: 4,
    ServiceRetention: 5,
    CreditNote: 8,
    DebitNote: 9,
  },

  AssetAccounClassId: 1,
  AssetControlAccountReceivableId: 2,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
