import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FireBaseAuthService} from '../services/fire-base-auth.service';

@Component({
  selector: 'app-login',
  providers: [MessageService],
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit{

  loginForm: FormGroup;
  dark: true;
  checked: boolean;

  constructor(
              private userService: UserService,
              private messageService: MessageService,
              private router: Router,
              private formBuilder: FormBuilder,
              private fireBaseAuthService: FireBaseAuthService) {
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      remember: [null],
    })
  }

  async loginWithGoogle() {
    await this.fireBaseAuthService.loginWithGoogle();
  }

  async login() {
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    this.userService.authenticateOtherUser(email, password).subscribe( async (res) => {
      if(res.responseCode=='00'){
        var data = res.responseData;
         this.fireBaseAuthService.grantSessionBasicSiginin(data)
         console.log(data);
      }else{
        this.messageService.add({severity:'error', summary: 'Login failure', detail: res.responseMsg});
      }
    }, error => {
      this.messageService.add({severity:'error', summary: 'Authentication Error', detail:'Invalid email or password'});
    });
  }
}

// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-login',
//   templateUrl: './app.login.component.html',
// })
// export class AppLoginComponent {

//   dark: boolean;

//   checked: boolean;

// }
