import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { FireBaseAuthService } from 'src/app/services/fire-base-auth.service';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.css']
})
export class TimeoutComponent implements OnInit {

  countDownTimer$: Observable<number>;

  constructor(
    public config: DynamicDialogConfig,
    public timeOutModalRef: DynamicDialogRef,
    public fireBaseAuthService: FireBaseAuthService
    ) { }

  ngOnInit(): void {
    this.countDownTimer$ = this.config.data.countDownTimer;
  }

  logout(){
    this.timeOutModalRef.close()
    this.fireBaseAuthService.logout()
  }

  closeModal() {
    this.timeOutModalRef.close();
  }
}
